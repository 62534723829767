@tailwind base;
@tailwind components;
@tailwind utilities; 

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@200..900&display=swap');

@font-face {
  font-family: 'Poppins';
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@200..900')
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url("./assets/fonts/Poppins-Medium.ttf")
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url("./assets/fonts/Poppins-Regular.ttf")
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200..900&display=swap'); */

html,
body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
} 

.ubuntu {
  font-family: 'Ubuntu', sans-serif; 
}

.poppins-regular {
  font-family: 'Poppins-Regular' ;
}

.poppins-medium {
  font-family: 'Poppins-Medium' ;
}